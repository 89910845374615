<template>
  <div id="index">
    <div id="fullpage">
      <header-comp @changeLanguage="changeLanguage" @homeData="homeData"></header-comp>
      <div class="section" id="cpjs">
        <swiper ref="mySwiper" :options="swiperOptions">
          <swiper-slide v-for="item in bannerImgList" :key="item.id">
            <img :src="isMobile?item.mobileUrl:item.url" :alt="item.name" width="100%" :height="isMobile?'300px':'100%'"
              class="bannerImg" />
          </swiper-slide>
        </swiper>
      </div>
      <div class="section" id="jjfa">
        <img :src="isMobile?syjjfasj:syjjfapc" alt="解决方案：S2B2C" width="100%" :height="isMobile?'360px':'100vh'"
          :class="isMobile?'bannerMobileImg':'bannerImg'" />
      </div>
      <div class="section">
        <img :src="isMobile?syxqjjfasj:syxqjjfapc" alt="中小型二手车商需求解决方案" width="100%" :height="isMobile?'258px':'100vh'"
          :class="isMobile?'bannerMobileImg':'bannerImg'" />
      </div>
      <div class="section last">
        <div :class="isMobile?'':'cooperativePartner'" id="lastTop">
          <img :src="isMobile?symfycsj1:symfycpc" alt="蜜蜂优车" width="100%" :height="isMobile?'285px':'720px'" id="mfyc" />
          <img :src="symfycsj2" alt="蜜蜂优车" width="100%" height="725px'" class="bannerMobileImg" v-if="isMobile" />
          <img :src="isMobile?syhzhbsj:syhzhbpc" alt="合作伙伴" width="100%" :class="isMobile?'bannerMobileImg':''"
            :height="isMobile?'176px':'650px'" id="hzhb" />
          <footer-comp></footer-comp>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import headerComp from '@/views/components/headerComp'
import footerComp from '@/views/components/footerComp'
import { swiper, swiperSlide } from "vue-awesome-swiper";
import fullpage from 'fullpage.js'
import { getBannerList } from '@/api/index'
export default {
  name: "index",
  components: {
    swiper, swiperSlide, headerComp, footerComp
  },
  data() {
    return {
      syjjfapc: '',
      syjjfasj: '',
      syxqjjfapc: '',
      syxqjjfasj: '',
      symfycpc: '',
      symfycsj1: '',
      symfycsj2: '',
      syhzhbpc: '',
      syhzhbsj: '',
      swiperOptions: {
        effect: 'fade',//slide(默认)、fade、cube、coverflow、flip
        slidesPerView: 1, //设置slider容器能够同时显示的slides数量,可以是小数,设置为2时,如图所示，设置为3则会出现三张完整的active slide，如API的例子,即设置为偶数时会自动使两边的缩进，类似遮盖一半的效果
        spaceBetween: 0,
        centeredSlides: true, //设定为true时，active slide会居中，而不是默认状态下的居左
        loop: false,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        slideToClickedSlide: true, //true:点击slide会过渡到这个slide,默认false
        height: window.innerHeight
      },
      fullpageInstance: null,
      queryParams: {
        enable: '1',
        languageType: '1'
      },
      bannerImgList: []
    };
  },
  computed: {
    isMobile() {
      // 获取 User-Agent 字符串
      const userAgent = window.navigator.userAgent;
      // 获取屏幕宽度
      const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      return /Mobile/i.test(userAgent) || screenWidth < 768;
    },
    isTablet() {
      // 获取 User-Agent 字符串
      const userAgent = window.navigator.userAgent;
      // 获取屏幕宽度
      const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      return /Tablet/i.test(userAgent) || (screenWidth >= 768 && screenWidth < 1024);
    }
  },
  methods: {
    getBanner() {
      getBannerList(this.queryParams).then(res => {
        this.bannerImgList = res.data.records
      }).catch(e => {
        console.log(`output->e`, e)
      })
    },
    changeLanguage(data) {
      console.log(`output->data888`,data)
      this.queryParams.languageType = data
      this.getBanner()
    },
    homeData(data) {
      if (data && data.length) {
        data.forEach(item => {
          if (item.dataKey === 'syjjfapc') {
            this.syjjfapc = item.dataValue
          } else if (item.dataKey === 'syjjfasj') {
            this.syjjfasj = item.dataValue
          } else if (item.dataKey === 'syxqjjfapc') {
            this.syxqjjfapc = item.dataValue
          } else if (item.dataKey === 'syxqjjfasj') {
            this.syxqjjfasj = item.dataValue
          }else if (item.dataKey === 'symfycpc') {
            this.symfycpc = item.dataValue
          }else if (item.dataKey === 'symfycsj1') {
            this.symfycsj1 = item.dataValue
          }else if (item.dataKey === 'symfycsj2') {
            this.symfycsj2 = item.dataValue
          }else if (item.dataKey === 'syhzhbpc') {
            this.syhzhbpc = item.dataValue
          }else if (item.dataKey === 'syhzhbsj') {
            this.syhzhbsj = item.dataValue
          }
        })
      }
    }
  },
  created() { },
  mounted() {
    this.languageType = sessionStorage.getItem('languageType')?sessionStorage.getItem('languageType'):'1'
    if (!this.isMobile) {
      this.fullpageInstance = new fullpage('#fullpage', {
        sectionsColor: ['#fff', '#fff', '#fff', '#fff', '#fff'],
        navigator: true,
        scrollOverflow: true,
        afterLoad: (a, b) => {
          if (a.index == 3 && b.index == 2) {
            this.$nextTick(() => {
              setTimeout(() => {
                let ele = document.getElementById('lastTop');
                ele.scrollTop = 0
              }, 0);
            });
          }
        }
      })
      bus.$emit('fullpageReady', this.fullpageInstance)
    }
    this.getBanner()
  },
  destroyed() {
    if (!this.isMobile) {
      if (this.fullpageInstance) {
        this.fullpageInstance.destroy('all');
      }
    }
  }
};
</script>
<style lang="less" scoped>
@import '../assets/css/style.default.css';
@import '../assets/css/custom.css';

.section {
  position: relative;
  width: 100%;

}

.swiper-container {
  width: 100%;
  height: 100%;

  /* 或者您需要的任何高度 */
  .swiper-slide {
    overflow: hidden;
    height: 211px;

    img {
      width: 100%;
      height: 211px;
    }
  }
}

@media (min-width:992px) {
  .section {
    height: 100vh;
    position: relative;
  }

  .swiper-container {
    height: 100vh;

    /* 或者您需要的任何高度 */
    .swiper-slide {
      height: 100vh;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}


.bannerImg {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-size: 100% 100%;
}

.bannerMobileImg {
  object-fit: contain;
}

.ad {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 30px;
  width: 90%;
  color: #fff;
  text-align: center;
}

@media (min-width: 992px) {
  .ad {
    font-size: 100px;
  }
}

/deep/.fp-watermark {
  display: none;
}

.cooperativePartner {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow-y: auto;

  img {
    object-fit: cover;
  }
}

@media (min-width:992px) {
  .last {
    height: 100vh;
    overflow-y: auto;
  }
}

/deep/.fp-overflow {
  height: 100vh;
}
</style>
